<template>
    <div class="row">
        <div class="col-sm-10 mx-auto">

            <div class="card">
                <div class="card-header">
                    <h1>{{$t("AgencyConfiguration.title")}}</h1>
                </div>
                <div class="card-body" v-if="loading">
                    <i class="fa fa-3x fa-spin fa-spinner"></i>
                </div>
                <div class="card-body" v-else>
                    <div class="row" v-if="!agencyData.configured">
                        <div class="col-12">
                            <div class="alert alert-danger">{{$t("AgencyConfiguration.mustbeconfigured")}}</div>
                        </div>
                    </div>
                    <form v-on:submit.prevent="saveConfig" novalidate>
                        <div class="row">
                            <div class="col-sm-6 col-xs-12">
                                <div class="form-group" :class="{ 'has-error': $v.agencyData.name.$error }">
                                    <label for="agencyName" class="control-label">{{$t("AgencyConfiguration.fields.agencyName")}} *</label>
                                    <input type="text" class="form-control" id="agencyName" name="agencyName"
                                           :class="{'is-invalid' : $v.agencyData.name.$error}"
                                           v-model.trim="$v.agencyData.name.$model">
                                    <div class="invalid-feedback"
                                         v-show="$v.agencyData.name.$error && !$v.agencyData.name.required">
                                        {{$t("AgencyConfiguration.fields.agencyName_required")}}
                                    </div>
                                    <div class="invalid-feedback"
                                         v-show="$v.agencyData.name.$error && !$v.agencyData.name.maxLength">
                                        {{$t("AgencyConfiguration.fields.agencyName_size")}}
                                    </div>
                                </div>

                                <div class="form-group" :class="{ 'has-error': $v.agencyData.email.$error }">
                                    <label for="email" class="control-label">{{$t("AgencyConfiguration.fields.email")}}
                                        *</label>
                                    <input type="text" class="form-control" id="email" name="email"
                                           :class="{'is-invalid' : $v.agencyData.email.$error}"
                                           v-model.trim="$v.agencyData.email.$model">
                                    <div class="invalid-feedback"
                                         v-show="$v.agencyData.email.$error && !$v.agencyData.email.required">
                                        {{$t("AgencyConfiguration.fields.email_required")}}
                                    </div>
                                    <div class="invalid-feedback"
                                         v-show="$v.agencyData.email.$error && !$v.agencyData.email.email">
                                        {{$t("AgencyConfiguration.fields.email_badformat")}}
                                    </div>
                                </div>

                                <div class="form-group" :class="{ 'has-error': $v.agencyData.website.$error }">
                                    <label for="website" class="control-label">{{$t("AgencyConfiguration.fields.website")}}</label>
                                    <input type="text" class="form-control" id="website" name="website"
                                           :class="{'is-invalid' : $v.agencyData.website.$error}"
                                           v-model.trim="$v.agencyData.website.$model">
                                    <!--<div class="invalid-feedback" v-show="$v.agencyData.website.$error && !$v.agencyData.website.required">This field is required.</div>-->
                                    <div class="invalid-feedback"
                                         v-show="$v.agencyData.website.$error && !$v.agencyData.website.url">
                                        {{$t("AgencyConfiguration.fields.website_badformat")}}
                                    </div>
                                    <div class="invalid-feedback"
                                         v-show="$v.agencyData.website.$error && !$v.agencyData.website.maxLength">
                                        {{$t("AgencyConfiguration.fields.website_size")}}
                                    </div>
                                </div>

                                <div class="form-group" :class="{ 'has-error': $v.agencyData.phone.$error }">
                                    <label for="phone" class="control-label">{{$t("AgencyConfiguration.fields.phone")}}
                                        *</label>
                                    <input type="text" class="form-control" id="phone" name="phone"
                                           :class="{'is-invalid' : $v.agencyData.phone.$error}"
                                           v-model.trim="$v.agencyData.phone.$model">
                                    <div class="invalid-feedback"
                                         v-show="$v.agencyData.phone.$error && !$v.agencyData.phone.required">
                                        {{$t("AgencyConfiguration.fields.phone_required")}}
                                    </div>
                                    <div class="invalid-feedback"
                                         v-show="$v.agencyData.phone.$error && !$v.agencyData.phone.maxLength">
                                        {{$t("AgencyConfiguration.fields.phone_size")}}
                                    </div>
                                </div>

                                <div class="form-group" :class="{ 'has-error': $v.agencyData.address.$error }">
                                    <label for="address" class="control-label">{{$t("AgencyConfiguration.fields.address")}}
                                        *</label>
                                    <input type="text" class="form-control" id="address" name="address"
                                           :class="{'is-invalid' : $v.agencyData.address.$error}"
                                           v-model.trim="$v.agencyData.address.$model">
                                    <div class="invalid-feedback"
                                         v-show="$v.agencyData.address.$error && !$v.agencyData.address.required">
                                        {{$t("AgencyConfiguration.fields.address_required")}}
                                    </div>
                                    <div class="invalid-feedback"
                                         v-show="$v.agencyData.address.$error && !$v.agencyData.address.maxLength">
                                        {{$t("AgencyConfiguration.fields.address_size")}}
                                    </div>
                                </div>

                                <div class="form-group" :class="{ 'has-error': $v.agencyData.city.$error }">
                                    <label for="city" class="control-label">{{$t("AgencyConfiguration.fields.city")}}
                                        *</label>
                                    <input type="text" class="form-control" id="city" name="city"
                                           :class="{'is-invalid' : $v.agencyData.city.$error}"
                                           v-model.trim="$v.agencyData.city.$model">
                                    <div class="invalid-feedback"
                                         v-show="$v.agencyData.city.$error && !$v.agencyData.city.required">
                                        {{$t("AgencyConfiguration.fields.city_required")}}
                                    </div>
                                    <div class="invalid-feedback"
                                         v-show="$v.agencyData.city.$error && !$v.agencyData.city.maxLength">
                                        {{$t("AgencyConfiguration.fields.city_size")}}
                                    </div>
                                </div>

                                <div class="form-group" :class="{ 'has-error': $v.agencyData.postalCode.$error }">
                                    <label for="postalCode" class="control-label">{{$t("AgencyConfiguration.fields.postalCode")}}
                                        *</label>
                                    <input type="text" class="form-control" id="postalCode" name="postalCode"
                                           :class="{'is-invalid' : $v.agencyData.postalCode.$error}"
                                           v-model.trim="$v.agencyData.postalCode.$model">
                                    <div class="invalid-feedback"
                                         v-show="$v.agencyData.postalCode.$error && !$v.agencyData.postalCode.required">
                                        {{$t("AgencyConfiguration.fields.postalCode_required")}}
                                    </div>
                                    <div class="invalid-feedback"
                                         v-show="$v.agencyData.postalCode.$error && !$v.agencyData.postalCode.maxLength">
                                        {{$t("AgencyConfiguration.fields.postalCode_size")}}
                                    </div>
                                </div>

                                <!--<div class="form-group" :class="{ 'has-error': $v.agencyData.country.$error }">
                                    <label for="country" class="control-label">{{$t("AgencyConfiguration.fields.country")}}
                                        *</label>
                                    <input type="text" class="form-control" id="country" name="country"
                                           :class="{'is-invalid' : $v.agencyData.country.$error}"
                                           v-model.trim="$v.agencyData.country.$model">
                                    <div class="invalid-feedback"
                                         v-show="$v.agencyData.country.$error && !$v.agencyData.country.required">
                                        {{$t("AgencyConfiguration.fields.country_required")}}
                                    </div>
                                    <div class="invalid-feedback"
                                         v-show="$v.agencyData.country.$error && !$v.agencyData.country.maxLength">
                                        {{$t("AgencyConfiguration.fields.country_size")}}
                                    </div>
                                </div>-->
                                <div class="form-group" :class="{ 'has-error': $v.agencyData.country.$error}">
                                    <label class="control-label" >{{$t("AgencyConfiguration.fields.country")}} *</label>
                                    <div class="d-flex">
                                        <el-select v-model="agencyData.country"
                                                   filterable
                                                   clearable
                                                   default-first-option
                                                   style="width:100%;color:black;font-size: 1rem;"
                                        >
                                            <el-option v-for="country in countryList"
                                                       :key="country.id"
                                                       :label="country.name"
                                                       :value="country.name"
                                            >
                                            </el-option>
                                        </el-select>
                                    </div>
                                    <input type="hidden" class="form-control"
                                           :class="{'is-invalid' : $v.agencyData.country.$error}"/>
                                    <div class="invalid-feedback"
                                         v-show="$v.agencyData.country.$error && !$v.agencyData.country.required">
                                        {{$t("AgencyConfiguration.fields.country_required")}}
                                    </div>
                                    <!--<div class="invalid-feedback"
                                         v-show="$v.agencyData.country.$error && !$v.agencyData.country.maxLength">
                                        {{$t("AgencyConfiguration.fields.country_size")}}
                                    </div>-->
                                </div>

                                <div class="form-group" :class="{ 'has-error': $v.agencyData.vat.$error }">
                                    <label for="vat"
                                           class="control-label">{{$t("AgencyConfiguration.fields.vat")}}</label>
                                    <input type="number" class="form-control" id="vat" name="vat"
                                           :class="{'is-invalid' : $v.agencyData.vat.$error}"
                                           v-model.trim="$v.agencyData.vat.$model">
                                    <div class="invalid-feedback"
                                         v-show="$v.agencyData.vat.$error && !$v.agencyData.vat.numeric">
                                        {{$t("AgencyConfiguration.fields.vat_onlynumbers")}}
                                    </div>
                                    <div class="invalid-feedback"
                                         v-show="$v.agencyData.vat.$error && !$v.agencyData.vat.maxLength">
                                        {{$t("AgencyConfiguration.fields.vat_size")}}
                                    </div>
                                </div>

                                <div class="form-group" :class="{ 'has-error': $v.agencyData.reg.$error }">
                                    <label for="reg"
                                           class="control-label">{{$t("AgencyConfiguration.fields.regnumb")}}</label>
                                    <input type="text" class="form-control" id="reg" name="reg"
                                           :class="{'is-invalid' : $v.agencyData.reg.$error}"
                                           v-model.trim="$v.agencyData.reg.$model">
                                    <!--<div class="invalid-feedback" v-show="$v.agencyData.reg.$error && !$v.agencyData.reg.numeric">Only numbers.</div>-->
                                    <div class="invalid-feedback"
                                         v-show="$v.agencyData.reg.$error && !$v.agencyData.reg.maxLength">
                                        {{$t("AgencyConfiguration.fields.regnumb_size")}}
                                    </div>
                                </div>

                                <hr/>

                                <div class="form-group">
                                    <label for="reg" class="control-label">{{$t("AgencyConfiguration.fields.date_format")}}</label>
                                    <select class="form-control" v-model="agencyData.dateFormat">
                                        <option v-for="dateFormat in dateFormats" :value="dateFormat.value"
                                                :key="dateFormat.value">
                                            {{dateFormat.label}}
                                        </option>
                                    </select>
                                </div>

                                <div class="form-group">
                                    <label class="control-label">{{$t("AgencyConfiguration.fields.currency")}}</label>
                                    <el-select v-model="agencyData.currencyCode"
                                               default-first-option
                                               filterable
                                               :placeholder='$t("AgencyConfiguration.fields.currency_select")'
                                               style="width:100%;"
                                    >
                                        <el-option v-for="curr in currencyList"
                                                   :key="curr.code"
                                                   :label="curr.label"
                                                   :value="curr.code"
                                        >
                                        </el-option>
                                    </el-select>

                                    <!--<select class="form-control" v-model="agencyData.currencyCode">
                                        <option v-for="currency in currencyList" :value="currency.code">{{currency.label}}</option>
                                    </select>-->
                                </div>

                                <!--<div class="form-group">
                                   <el-switch
                                           class="pr-2"
                                           v-model="agencyData.showChat"
                                           :active-text='$t("MobileAppsConfiguration.fields.showChatLabel")'
                                   ></el-switch>
                               </div>-->

                               <hr/>

                                 <div class="form-group">
                                   <el-switch
                                           class="pr-2"
                                           v-model="agencyData.singleMode"
                                           :active-text='$t("MobileAppsConfiguration.fields.singleMode")'
                                   ></el-switch>
                               </div>

                               <hr/>

                               <div class="form-group" :class="{ 'has-error': $v.agencyData.inviteCode.$error }">
                                    <label for="inviteCode"
                                           class="control-label">{{$t("AgencyConfiguration.fields.inviteCode")}}</label>
                                    <div class="row">
                                        <div class="col">
                                            <input type="text" class="form-control" id="inviteCode" name="inviteCode"
                                                :class="{'is-invalid' : $v.agencyData.inviteCode.$error}"
                                                v-model.trim="inviteCode">
                                            <div class="invalid-feedback"
                                                v-show="$v.agencyData.inviteCode.$error && !$v.agencyData.inviteCode.maxLength">
                                                {{$t("AgencyConfiguration.fields.inviteCode_maxLength")}}
                                            </div>
                                            <div class="invalid-feedback"
                                                v-show="$v.agencyData.inviteCode.$error && !$v.agencyData.inviteCode.minLength">
                                                {{$t("AgencyConfiguration.fields.inviteCode_minLength")}}
                                            </div>
                                            <div class="invalid-feedback"
                                                v-show="$v.agencyData.inviteCode.$error && !$v.agencyData.inviteCode.upperAlpha">
                                                {{$t("AgencyConfiguration.fields.inviteCode_upperAlpha")}}
                                            </div>
                                            <div class="invalid-feedback"
                                                v-show="$v.agencyData.inviteCode.$error && !$v.agencyData.inviteCode.isUnique">
                                                {{$t("AgencyConfiguration.fields.inviteCode_isUnique")}}
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <v-button
                                                :loading="generatingCode"
                                                class="form-control btn btn-secondary"
                                                type="button"
                                                @click="generateInviteCode"
                                            >
                                                {{$t("AgencyConfiguration.fields.inviteCode_generate")}}
                                            </v-button>
                                        </div>
                                    </div>
                                </div>


                                <v-button type="submit" :loading="saveLoading" class="btn btn-primary float-right"><i
                                        class="fa fa-save" aria-hidden="true"></i>&nbsp;{{$t("AgencyConfiguration.buttons.updateConfiguration")}}
                                </v-button>
                                <a :href="indexUrl" class="btn btn-link float-right" style="margin-right: 10px;">
                                    {{$t("AgencyConfiguration.buttons.backbutton")}}</a>

                            </div>
                            <div class="col-sm-6 col-xs-12">
                                <label class="control-label">{{$t("AgencyConfiguration.fields.agencyLogo")}}</label>
                                <picture-input
                                        v-if="showPictureInput"
                                        ref="pictureInput"
                                        margin="16"
                                        accept="image/jpeg,image/png"
                                        size="10"
                                        button-class="btn btn-primary"
                                        remove-button-class="btn btn-danger"
                                        :crop="false"
                                        :prefill="imageUrl"
                                        :removable="true"
                                        :custom-strings='customStrings'
                                        @change="onPictureChange"
                                        @remove="onPictureRemove"
                                >
                                </picture-input>

                                <div class="form-group" style="margin-top: 13px;">
                                    <label for="fb_link" class="control-label">{{
                                        $t("AgencyConfiguration.fields.facebookLink") }}</label>
                                    <input class="form-control" v-model="agencyData.facebookLink"
                                           :class="{'is-invalid' : $v.agencyData.facebookLink.$error}"
                                           id="fb_link">
                                    <div class="invalid-feedback"
                                         v-show="$v.agencyData.facebookLink.$error && !$v.agencyData.facebookLink.maxLength">
                                        {{ $t("AgencyConfiguration.fields.genericMaxSize", {max: $v.agencyData.facebookLink.$params.maxLength.max}) }}
                                    </div>
                                    <div class="invalid-feedback"
                                         v-show="!$v.agencyData.facebookLink.url">
                                        {{ $t("AgencyConfiguration.fields.website_badformat") }}
                                    </div>

                                </div>

                                <div class="form-group">
                                    <label for="ist_link" class="control-label">{{
                                        $t("AgencyConfiguration.fields.instagramLink") }}</label>
                                    <input class="form-control" v-model="agencyData.instagramLink"
                                           :class="{'is-invalid' : $v.agencyData.instagramLink.$error}"
                                           id="ist_link">
                                    <div class="invalid-feedback"
                                         v-show="$v.agencyData.instagramLink.$error && !$v.agencyData.instagramLink.maxLength">
                                        {{ $t("AgencyConfiguration.fields.genericMaxSize", {max: $v.agencyData.instagramLink.$params.maxLength.max}) }}
                                    </div>
                                    <div class="invalid-feedback"
                                         v-show="!$v.agencyData.instagramLink.url">
                                        {{ $t("AgencyConfiguration.fields.website_badformat") }}
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label for="twt_link" class="control-label">{{
                                        $t("AgencyConfiguration.fields.twitterLink") }}</label>
                                    <input class="form-control" v-model="agencyData.twitterLink"
                                           :class="{'is-invalid' : $v.agencyData.twitterLink.$error}"
                                           id="twt_link">
                                    <div class="invalid-feedback"
                                         v-show="$v.agencyData.twitterLink.$error && !$v.agencyData.twitterLink.maxLength">  <!--TODO validar url-->
                                        {{ $t("AgencyConfiguration.fields.genericMaxSize", {max: $v.agencyData.twitterLink.$params.maxLength.max}) }}
                                    </div>
                                    <div class="invalid-feedback"
                                         v-show="!$v.agencyData.twitterLink.url">
                                        {{ $t("AgencyConfiguration.fields.website_badformat") }}
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label for="yt_link" class="control-label">{{
                                        $t("AgencyConfiguration.fields.youtubeLink") }}</label>
                                    <input class="form-control" v-model="agencyData.youtubeLink"
                                           :class="{'is-invalid' : $v.agencyData.youtubeLink.$error}"
                                           id="yt_link">
                                    <div class="invalid-feedback"
                                         v-show="$v.agencyData.youtubeLink.$error && !$v.agencyData.youtubeLink.maxLength">
                                        {{ $t("AgencyConfiguration.fields.genericMaxSize", {max: $v.agencyData.youtubeLink.$params.maxLength.max}) }}
                                    </div>
                                    <div class="invalid-feedback"
                                         v-show="!$v.agencyData.youtubeLink.url">
                                        {{ $t("AgencyConfiguration.fields.website_badformat") }}
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label for="descfrm" class="control-label">{{
                                        $t("AgencyConfiguration.fields.description") }}</label>
                                    <textarea class="form-control" rows="10" v-model="agencyData.description"
                                           id="descfrm"></textarea>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import {handleAjaxError, showError, showSuccess} from "../../utils";
    import {required, email, maxLength, numeric, url, minLength, helpers} from 'vuelidate/lib/validators';
    import vButton from '../../components/VButton';
    import PictureInput from 'vue-picture-input'
    import {getDateFormatOptions} from "../../date-utils";
    import debounce from 'lodash/debounce';

    const upperAlpha = helpers.regex('upperAlpha', /^[A-Z]*$/);

    export default {
        components: {
            vButton,
            PictureInput,
        },
        // props: ["uuid"],
        data() {
            return {
                agencyData: {
                    name: null,
                    email: null,
                    website: null,
                    phone: null,
                    address: null,
                    city: null,
                    postalCode: null,
                    country: null,
                    vat: null,
                    reg: null,
                    imageFilename: null,
                    imageFile: null,
                    removeImage: false,
                    isUserAdmin: false,
                    configured: false,
                    dateFormat: "",
                    currencyCode: "",
                    showChat: false,

                    facebookLink: "",
                    instagramLink: "",
                    twitterLink: "",
                    youtubeLink: "",
                    description: "",

                    singleMode: false,
                    inviteCode: ""
                },

                dateFormats: getDateFormatOptions(),
                currencyList: [],
                countryList: [],

                loading: false,
                saveLoading: false,
                showPictureInput: false,

                generatingCode: false,
            }
        },
        validations: {
            agencyData: {
                name: {
                    required,
                    maxLength: maxLength(255),
                },
                email: {
                    required,
                    email,
                    maxLength: maxLength(255),
                },
                website: {
                    url,
                    maxLength: maxLength(255),
                },
                phone: {
                    required,
                    maxLength: maxLength(100),
                },
                address: {
                    required,
                    maxLength: maxLength(500),
                },
                city: {
                    required,
                    maxLength: maxLength(100),
                },
                postalCode: {
                    required,
                    maxLength: maxLength(45),
                },
                country: {
                    required,
                    maxLength: maxLength(100),
                },
                vat: {
                    numeric,
                    maxLength: maxLength(45),
                },
                reg: {
                    maxLength: maxLength(255),
                },

                facebookLink: {
                    maxLength: maxLength(500),
                    url,
                },
                twitterLink: {
                    maxLength: maxLength(500),
                    url,
                },
                instagramLink: {
                    maxLength: maxLength(500),
                    url,
                },
                youtubeLink: {
                    maxLength: maxLength(500),
                    url,
                },
                description: {
                    maxLength: maxLength(2048),
                },

                inviteCode: {
                    maxLength: maxLength(12),
                    minLength: minLength(4),
                    upperAlpha,
                    isUnique(value) {
                        if (value === '') return true;

                        const url = jsRoutes.controllers.AdministrationController.checkAgencyInviteCode().url;

                        return this.$http.post(url, { inviteCode: value })
                            .then(response => response.data);
                    }
                },

            }
        },
        mounted() {
            this.loadData();
            this.loadCurrencies();
            this.loadCountryList();
            this.$nextTick(() => {
                this.showPictureInput = true;
            })
        },
        computed: {
            customStrings() {
                return {
                    upload: '<h1>Bummer!</h1>',
                    drag: '<i class=\'fa fa-plus-circle\'></i><br/>' + this.$t('AgencyConfiguration.fields.agencyLogoHelp')
                }
            },
            indexUrl() {
                // eslint-disable-next-line
                return jsRoutes.controllers.Application.index().url;
            },
            imageUrl() {
                if (this.agencyData.imageFilename !== null) {
                    // eslint-disable-next-line
                    return jsRoutes.controllers.Application.agencyImage(this.agencyData.imageFilename).url
                } else {
                    return null;
                }
            },
            inviteCode: {
                get() {
                    return this.$v.agencyData.inviteCode.$model;
                },
                set: debounce(function (newValue) {
                    this.$v.agencyData.inviteCode.$model = newValue;
                }, 250)
            }
        },
        methods: {
            loadData() {
                this.loading = true;
                const self = this;
                // eslint-disable-next-line
                const url = jsRoutes.controllers.AdministrationController.getAgencyConfiguration().url;

                this.$http.get(url).then(function (response) {
                    self.loading = false;
                    //console.log("roles -> " + JSON.stringify(data));
                    self.agencyData = response.data;

                }).catch(function () {
                    self.loading = false;
                    // handle error
                    showError(
                        self.$t('error.general_title'),
                        self.$t('error.general_failed_loading_data')
                    );
                });
            },
            loadCurrencies() {
                const self = this;
                // eslint-disable-next-line
                const url = jsRoutes.controllers.Application.currencyListSelect().url;
                this.$http.get(url)
                    .then(function (response) {
                        self.currencyList = response.data;
                    })
                    .catch(function (error) {
                        // handle error
                        handleAjaxError(error);
                    });
            },
            loadCountryList() {
                const self = this;
                // eslint-disable-next-line
                const url = jsRoutes.controllers.Application.countryListSelect().url;
                this.$http.get(url).then(function(response) {
                    self.countryList = response.data;
                }, function(response) {
                    handleAjaxError(response);
                });

            },
            onPictureChange() {
                if (this.$refs.pictureInput.image) {
                    console.log('Picture loaded.');
                    this.agencyData.imageFile = this.$refs.pictureInput.file;
                }
            },
            onPictureRemove() {
                console.log('Picture remove.');
                this.agencyData.removeImage = true;
            },
            saveConfig() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    // console.log("### INVALID");
                    return;
                }
                this.doSaveConfig();
            },

            doSaveConfig: function () {
                const self = this;
                // eslint-disable-next-line
                const url = jsRoutes.controllers.AdministrationController.updateAgencyConfiguration().url;

                const formData = new FormData();
                formData.append("name", this.agencyData.name);
                formData.append("email", this.agencyData.email);
                formData.append("website", this.agencyData.website);
                formData.append("phone", this.agencyData.phone);
                formData.append("address", this.agencyData.address);
                formData.append("city", this.agencyData.city);
                formData.append("postalCode", this.agencyData.postalCode);
                formData.append("country", this.agencyData.country);
                formData.append("vat", this.agencyData.vat);
                formData.append("reg", this.agencyData.reg);
                formData.append("dateFormat", this.agencyData.dateFormat);
                formData.append("currencyCode", this.agencyData.currencyCode);
                formData.append("imageFile", this.agencyData.imageFile);
                formData.append("removeImage", this.agencyData.removeImage);
                formData.append("showChat", this.agencyData.showChat);

                formData.append("youtubeLink", this.agencyData.youtubeLink);
                formData.append("facebookLink", this.agencyData.facebookLink);
                formData.append("instagramLink", this.agencyData.instagramLink);
                formData.append("twitterLink", this.agencyData.twitterLink);

                formData.append("description", this.agencyData.description);


                formData.append("singleMode", this.agencyData.singleMode);
                formData.append("inviteCode", this.agencyData.inviteCode);

                this.saveLoading = true;
                axios({
                    method: 'post',
                    url: url,
                    data: formData,
                    config: {headers: {'Content-Type': 'multipart/form-data'}}
                })
                //this.$http.post(url, this.agencyData)
                    .then(function () {
                        self.saveLoading = false;
                        self.$v.$reset();
                        //console.log("roles -> " + JSON.stringify(data));
                        showSuccess(
                            self.$t('success.general_title'),
                            self.$t('AgencyConfiguration.messages.configuration_updated')
                        );
                        self.loadData();
                        //window.location.href = jsRoutes.controllers.AdministrationController.users().url;
                    })
                    .catch(function (error) {
                        self.saveLoading = false;
                        // handle error
                        handleAjaxError(error);
                    });
            },
            generateInviteCode() {
                // eslint-disable-next-line
                const url = jsRoutes.controllers.AdministrationController.generateAgencyInviteCode().url;

                this.generatingCode = true;

                this.$http
                    .get(url)
                    .then(response => this.$v.agencyData.inviteCode.$model = response.data)
                    .catch(error => handleAjaxError(error))
                    .finally(() => this.generatingCode = false);
            }
        }
    }
</script>

<style scoped>

</style>
