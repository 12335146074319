export default {
    "appname": "Morego App",
    "error.general_title": "Error",
    "error.general_failed_loading_data": "Failed loading data",
    "error.general_failed_upload_file": "Upload of file '{file}' failed: {msg}",
    "error.general_cancel": "Cancel",
    "error.general_delete": "Delete",
    "success.general_title": "Success",
    "success.image_caption_updated": "Image caption updated",
    "root.close": "Close",
    "root.organization": "Agency Name",
    "root.required": "This field is required.",
    "root.organization_size": "Please enter at least 3 characters.",
    "root.organization_max": "The max size is 255.",
    "root.firstname": "First Name",
    "root.lastname": "Last Name",
    "root.email": "Email",
    "root.email_valid": "The email address is not valid",
    "root.phone": "Phone",
    "root.choose_password": "Choose a password",
    "root.choose_password_size": "Please enter at least 6 characters.",
    "root.choose_password_max": "The max size is 255.",
    "root.confirm_password": "Confirm password",
    "root.confirm_password_match": "Passwords must match.",
    "root.cancel": "Cancel",
    "root.create_account": "Create account",
    "root.something_weng_wrong": "Something went wrong...",
    "root.invited_as_user": "You were invited as a user for the organization",
    "root.already_have_account": "Already have an account? ",
    "root.return_to_login": "Return to log in",
    "root.trial_period_ends": "Trial period ends on",
    "root.last_subscription_period_end": "Last subscription period ends on",
    "root.subscription_ended": "Your subscription ended on",
    "root.manage_your_subscriptions": "Billing",
    "root.profile": "Profile",
    "root.change_password": "Change Password",
    "root.profile_image": "Profile image",
    "root.upload_new_image": "Upload new image",
    "root.choose_file": "Choose File",
    "root.remove_image": "Remove Image",
    "root.save": "Save",
    "root.profile_updated": "Profile updated",
    "root.image_removed": "Image removed",
    "root.only_images_allowed": "Only image files allowed",
    "root.api_not_supported": "Sorry, FileReader API not supported",
    "root.file_uploaded": "File uploaded",
    "root.saved": "Saved",
    "widgets.imageCropModal.position_and_size": "Position and size your new image",
    "widgets.imageCropModal.save_button": "Save",
    "widgets.imageCropModal.close_button": "Close",
    "MobileAppsConfiguration.title": "Mobile App Builds",
    "MobileAppsConfiguration.table.request_date": "Request Date",
    "MobileAppsConfiguration.table.status": "Status",
    "MobileAppsConfiguration.table.feedback": "Feedback",
    "MobileAppsConfiguration.table.no_build_request": "No builds requested yet",
    "MobileAppsConfiguration.button.request_new_build": "Request New Build",
    "MobileAppsConfiguration.button.cancel_build": "Cancel Build",
    "MobileAppsConfiguration.button.update_configuration": "Save Configuration",
    "MobileAppsConfiguration.button.cancel": "Go Back",
    "MobileAppsConfiguration.title2": "Mobile App Configuration",
    "MobileAppsConfiguration.fields.agencyName": "Agency Name",
    "MobileAppsConfiguration.fields.about_agency": "About The Agency",
    "MobileAppsConfiguration.fields.about_agency_required": "This field is required.",
    "MobileAppsConfiguration.fields.primary_color": "Primary Color",
    "MobileAppsConfiguration.fields.primary_color_required": "This field is required.",
    "MobileAppsConfiguration.fields.background_color": "Background Color",
    "MobileAppsConfiguration.fields.background_color_required": "This field is required.",
    "MobileAppsConfiguration.fields.mobile_logo": "Mobile Logo",
    "MobileAppsConfiguration.fields.mobile_icon": "Mobile Icon",
    "MobileAppsConfiguration.fields.imagesHelp": "Click or drag an image",
    "MobileAppsConfiguration.fields.showChatLabel": "Enable Chat On Mobile App",
    "MobileAppsConfiguration.messages.mobile_request_title": "Mobile Build Request",
    "MobileAppsConfiguration.messages.mobile_request_question": "Request build with the current configurations?",
    "MobileAppsConfiguration.messages.mobile_request_button": "Request Build",
    "MobileAppsConfiguration.messages.cancel_mobile_request_title": "Cancel Mobile Build",
    "MobileAppsConfiguration.messages.cancel_mobile_request_question": "Cancel this build request?",
    "MobileAppsConfiguration.messages.cancel_mobile_request_button": "Cancel Build",
    "MobileAppsConfiguration.messages.configuration_updated": "Configuration updated",
    "AgencyConfiguration.title": "Creator Configuration",
    "AgencyConfiguration.mustbeconfigured": "The agency information needs to be configured!",
    "AgencyConfiguration.fields.agencyName": "Creator Name",
    "AgencyConfiguration.fields.agencyName_required": "This field is required.",
    "AgencyConfiguration.fields.agencyName_size": "The max size is 255.",
    "AgencyConfiguration.fields.email": "Email",
    "AgencyConfiguration.fields.email_required": "This field is required.",
    "AgencyConfiguration.fields.email_badformat": "The email address is not valid",
    "AgencyConfiguration.fields.website": "Creator website",
    "AgencyConfiguration.fields.website_badformat": "The URL is not valid.",
    "AgencyConfiguration.fields.website_size": "The max size is 255.",
    "AgencyConfiguration.fields.phone": "Phone",
    "AgencyConfiguration.fields.phone_required": "This field is required.",
    "AgencyConfiguration.fields.phone_size": "The max size is 100.",
    "AgencyConfiguration.fields.address": "Address",
    "AgencyConfiguration.fields.address_required": "This field is required.",
    "AgencyConfiguration.fields.address_size": "The max size is 500.",
    "AgencyConfiguration.fields.city": "City",
    "AgencyConfiguration.fields.city_required": "This field is required.",
    "AgencyConfiguration.fields.city_size": "The max size is 100.",
    "AgencyConfiguration.fields.postalCode": "Postal Code",
    "AgencyConfiguration.fields.postalCode_required": "This field is required.",
    "AgencyConfiguration.fields.postalCode_size": "The max size is 45.",
    "AgencyConfiguration.fields.country": "Country",
    "AgencyConfiguration.fields.country_required": "This field is required.",
    "AgencyConfiguration.fields.country_size": "The max size is 100.",
    "AgencyConfiguration.fields.vat": "VAT",
    "AgencyConfiguration.fields.vat_onlynumbers": "Only numbers.",
    "AgencyConfiguration.fields.vat_size": "The max size is 45.",
    "AgencyConfiguration.fields.regnumb": "Registration Number",
    "AgencyConfiguration.fields.regnumb_size": "The max size is 255.",
    "AgencyConfiguration.fields.date_format": "Default Date format",
    "AgencyConfiguration.fields.currency": "Default Currency",
    "AgencyConfiguration.fields.currency_select": "Select currency",
    "AgencyConfiguration.fields.agencyLogo": "Creator Logo",
    "AgencyConfiguration.fields.agencyLogoHelp": "Click or drag an image",
    "AgencyConfiguration.fields.facebookLink": "Facebook",
    "AgencyConfiguration.fields.instagramLink": "Instagram",
    "AgencyConfiguration.fields.twitterLink": "Twitter",
    "AgencyConfiguration.fields.youtubeLink": "Youtube",
    "AgencyConfiguration.fields.description": "Description",
    "AgencyConfiguration.buttons.updateConfiguration": "Update Configuration",
    "AgencyConfiguration.buttons.backbutton": "Go back",
    "AgencyConfiguration.messages.configuration_updated": "Configuration updated",
    "AgencyConfiguration.fields.inviteCode": "Invite code",
    "AgencyConfiguration.fields.inviteCode_generate": "Generate",
    "AgencyConfiguration.fields.inviteCode_maxLength": "The max size is 12",
    "AgencyConfiguration.fields.inviteCode_minLength": "The min size is 4",
    "AgencyConfiguration.fields.inviteCode_upperAlpha": "Only uppercase latin characters",
    "AgencyConfiguration.fields.inviteCode_isUnique": "Must be unique",
    "SubscriptionManager.title": "Billing",
    "SubscriptionManager.invoicesTitle": "Invoices",
    "SubscriptionManager.loadingInvoices": "Loading invoices...",
    "SubscriptionManager.tab.subscription": "Your Subscription",
    "SubscriptionManager.tab.invoices": "Your Invoices",
    "SubscriptionManager.tab.payment_methods": "Your Payment Methods",
    "SubscriptionManager.monthlyPlan": "Your Subscription",
    "SubscriptionManager.planAmount": "Plan Amount",
    "SubscriptionManager.subscriptionTitle": "Your Subscription",
    "SubscriptionManager.cardsTitle": "Credit Cards",
    "SubscriptionManager.loadingCards": "Loading Cards...",
    "SubscriptionManager.availablePlansTitle": "Available Plans",
    "SubscriptionManager.confirmationTitle": "Confirmation",
    "SubscriptionManager.table.period": "Period",
    "SubscriptionManager.table.amount": "Amount",
    "SubscriptionManager.button.download": "Download",
    "SubscriptionManager.button.cancel_subscription": "Cancel Subscription",
    "SubscriptionManager.button.subscribe": "Subscribe",
    "SubscriptionManager.button.use_this_card": "Pay now",
    "SubscriptionManager.button.i_am_sure": "I`m sure",
    "SubscriptionManager.fields.last_subscription_ends": "Last subscription period ends on",
    "SubscriptionManager.fields.subscription_ended": "Your subscription ended on",
    "SubscriptionManager.fields.subscription_renew": "Your subscription will auto-renew on",
    "SubscriptionManager.fields.expire_card": "Exp",
    "SubscriptionManager.fields.selected": "Selected",
    "SubscriptionManager.fields.payment_options": "Payment Method",
    "SubscriptionManager.fields.card_details": "Please provide details of the card to be used for billing",
    "SubscriptionManager.fields.ending_with": "ending with",
    "SubscriptionManager.fields.valid_until": "Valid until",
    "SubscriptionManager.fields.important" : "Important",
    "SubscriptionManager.fields.trial_period_ended": "Trial period ended on",
    "SubscriptionManager.fields.trial_period_ends": "Trial period ends on" ,
    "SubscriptionManager.fields.suspended_account_nostripe": "You account is suspended at this moment. Please subscribe to Morego to continue.", // TODO
    "SubscriptionManager.fields.suspended_account_updatemethod": "You account is suspended at this moment. This may be due to lack or payment or end of Subscription. Please supdate your payment method or subscribe to continue using Morego.", // TODO
    "SubscriptionManager.fields.eur_cur": "EURO", // not used
    "SubscriptionManager.fields.dol_cur": "DOLLAR", // not used
    "SubscriptionManager.fields.gbp_cur": "GBP", // not used
    "SubscriptionManager.button.update_card" : "Update Using This Card",
    "SubscriptionManager.invoice.date": "Invoice Date",
    "SubscriptionManager.invoice.value": "Amount",
    "SubscriptionManager.warning.stripeproblem": "There was a problem with your Credit Card: ",
    "SubscriptionManager.warning.subscription_not_active" : "Your Subscription is not Active. Please check you Subscription details.", // TODO
    "SubscriptionManager.warning.canceled_but_still_time" : "Your subscription was canceled and will stop working at this date: ", // TODO
    "SubscriptionManager.warning.account_locked_nosubs" : "Your account is now locked because you don't have a subscription. To continue using Morego please check the Billing Section. ", // TODO
    "SubscriptionManager.warning.trial_ongoing" : "You have a trial subscription until the following date: ", // TODO
    "SubscriptionManager.warning.trial_finished" : "You have a trial subscription has ended. To continue using Morego go to the Billing section and Subscribe.", // TODO
    "SubscriptionManager.warning.generic_lock" : "You don't have an active subscription. Please check the Billing Section.", // TODO
    "UserInvite.title": "Invite Users",
    "UserInvite.fields.email": "Email",
    "UserInvite.fields.email_required": "This field is required.",
    "UserInvite.fields.email_invalid": "The email address is not valid.",
    "UserInvite.fields.user_type": "Type",
    "UserInvite.fields.user_type_required": "This field is required.",
    "UserInvite.button.send_invite": "Send Invite",
    "UserInvite.button.go_back": "Go Back",
    "UserInvite.messages.failed_loading_roles": "Loading roles error",
    "UserInvite.messages.invite_sent": "Invite sent",
    "UserList.title": "Users",
    "UserList.invites_title": "Invites",
    "UserList.button.invite_user": "Invite user",
    "UserList.table.name": "Name",
    "UserList.table.email": "Email",
    "UserList.table.type": "Type",
    "UserList.table.sent_by": "Sent by",
    "UserList.table.date": "Date",
    "UserList.messages.delete_user": "Delete user",
    "UserList.messages.invites_table_empty": "Invites list empty",
    "UserList.messages.delete_invite": "Delete invite",
    "ClientList.title": "Subscribers",
    "ClientList.tooltip.import_clients": "Import your existing subscribers from an Excel file",
    "ClientList.button.create_client": "Create Subscriber",
    "ClientList.button.import_clients": "Import Subscribers",
    "ClientList.button.edit": "Edit",
    "ClientList.button.del": "Delete",
    "ClientList.table.name": "Name",
    "ClientList.table.email": "Email",
    "ClientList.table.phone": "Phone",
    "ClientList.table.actions": "Actions",
    "ClientList.messages.delete_title": "Are you sure?",
    "ClientList.messages.delete_make_sure_1": "Once removed,",
    "ClientList.messages.delete_make_sure_2": "will no longer be able to access his data!",
    "ClientList.messages.delete_title_success": "The subscriber was deleted",
    "ClientImport.title": "Import Subscribers",
    "ClientImport.go_back": "Go Back",
    "ClientImport.button.create_client": "Create Subscriber",
    "ClientImport.button.import_clients": "Import Subscribers",
    "ClientImport.button.edit": "Edit",
    "ClientImport.button.del": "Delete",
    "ClientImport.file_warning_part1": "IMPORTANT: The file to upload should have the same format has this ",
    "ClientImport.file_warning_part2": " sample",
    "ClientImport.processing": "Processing...",
    "ClientImport.results": "Results",
    "ClientImport.dropzone_remove_file": "Remove file",
    "ClientImport.dropzone_invalid_file_type": "Only .xlsx files are accepted",
    "ClientModal.update_client_title": "Update Subscriber",
    "ClientModal.create_client_title": "Create Subscriber",
    "ClientModal.fields.name": "Name",
    "ClientModal.fields.name_required": "This field is required.",
    "ClientModal.fields.name_size": "The max size is 255.",
    "ClientModal.fields.email": "Email",
    "ClientModal.fields.email_required": "This field is required.",
    "ClientModal.fields.email_format": "The email address is not valid",
    "ClientModal.fields.phone": "Phone",
    "ClientModal.fields.phone_size": "The max size is 100.",
    "ClientModal.button.create_client": "Create Subscriber",
    "ClientModal.button.update_client": "Update Subscriber",
    "ClientModal.messages.client_created": "Subscriber created",
    "ClientModal.messages.client_updated": "Subscriber updated",
    "Itinerary.general.save_to_library": "Save to Library",
    "Itinerary.general.update_to_library": "Update library",
    "Itinerary.general.remove_item": "Remove Item",
    "Itinerary.general.remove_line": "Remove Line",
    "Itinerary.general.add_line": "Add line",
    "Itinerary.general.click_to_edit": "Click to edit",
    "Itinerary.general.click_or_drag": "Click or drag an image",
    "Itinerary.general.click_or_drag_images_to_upload": "Click or drag images here to upload",
    "Itinerary.general.click_or_drag_to_upload": "Click or drag files here to upload",
    "Itinerary.general.show_on_map": "Show on Map",
    "Itinerary.general.return_string": "Return",
    "Itinerary.general.ongoing_string": "Ongoing",
    "Itinerary.general.move_to_other_section": "Move to other section",
    "Itinerary.general.autocomplete_information": "Autocomplete information",
    "Itinerary.general.image_caption": "Write image caption here",
    "Itinerary.general.add_images": "Add Images",
    "Itinerary.general.add_rating": "Add Rating",
    "Itinerary.general.add_item": "Add Item",
    "Itinerary.general.google_rating": "Google rating",
    "Itinerary.general.open_website": "Open Website",
    "Itinerary.general.website": "Website",
    "Itinerary.general.label": "Label",
    "Itinerary.general.link": "Link",
    "Itinerary.general.close": "Close",
    "Itinerary.general.add_from_library": "Add from your library",
    "Itinerary.general.load_from_library": "Load from your library",
    "Itinerary.general.days": "days",
    "Itinerary.general.invalid_fields_error": "There are invalid fields",
    "Itinerary.builder.add_cover": "Add Cover Section",
    "Itinerary.builder.add_section": "Add Section",
    "Itinerary.builder.add_day_section": "Add Day",
    "Itinerary.builder.car_rental_return": "Car rental return",
    "Itinerary.builder.car_rental_pickup": "Car rental pickup",
    "Itinerary.builder.car_rental": "Car rental",
    "Itinerary.builder.checkout": "Checkout",
    "Itinerary.builder.checkin": "Checkin",
    "Itinerary.builder.flight": "Flight",
    "Itinerary.builder.click_to_change_title": "Click to change title",
    "Itinerary.builder.remove_section": "Remove Section",
    "Itinerary.builder.add_item": "Add item",
    "Itinerary.builder.reorder_items": "Reorder items",
    "Itinerary.builder.show_title_on_pdf": "Show title on PDF",
    "Itinerary.builder.new_section": "New section",
    "Itinerary.builder.new_general_section": "Other Section",
    "Itinerary.builder.new_day": "New Day",
    "Itinerary.builder.new_day_message": "A new Day was added. Please check the titles of the remaining days and dates of the travel asset",
    "Itinerary.builder.new_end_section": "New end section",
    "Itinerary.builder.are_you_sure": "Are you sure?",
    "Itinerary.builder.remove_message": "Remove these sections and all its items?",
    "Itinerary.builder.remove_button": "Remove",
    "Itinerary.builder.remove_item_message": "Remove this item?",
    "Itinerary.builder.item_moved": "Item moved",
    "Itinerary.builder.dates_updated": "Dates updated",
    "Itinerary.builder.add_item_problem_title": "Problem...",
    "Itinerary.builder.add_item_problem_msg": "There is no day section for the first day, {day}",
    "Itinerary.builder.create.create_new_itinerary": "Create New travel asset",
    "Itinerary.builder.create.itinerary_language": "Language to use in the travel asset",
    "Itinerary.builder.create.select_days_keep_template_warning": "Select the days to keep from the template. {numDays} MAX!",
    "Itinerary.builder.create.select_days_error": "Your travel asset will have {numDays} days. Select at most {numDays} days from the template.",
    "Itinerary.builder.create.name": "Name",
    "Itinerary.builder.create.name_required": "This field is required.",
    "Itinerary.builder.create.name_size": "The max size is 255.",
    "Itinerary.builder.create.date_format": "Date format",
    "Itinerary.builder.create.use_itinerary_template": "Use travel asset as template (optional)",
    "Itinerary.builder.create.open_in_new_tab_tooltip": "Open in new tab",
    "Itinerary.builder.create.select_itinerary": "Select travel asset",
    "Itinerary.builder.create.dates": "Dates",
    "Itinerary.builder.create.start_date": "Start date",
    "Itinerary.builder.create.end_date": "End date",
    "Itinerary.builder.create.field_required": "This field is required.",
    "Itinerary.builder.create.countries": "Countries",
    "Itinerary.builder.create.select_countries": "Select countries",
    "Itinerary.builder.create.currency": "Currency",
    "Itinerary.builder.create.currency_select": "Select currency",
    "Itinerary.builder.create.clients": "Subscribers",
    "Itinerary.builder.create.clients_select": "Select subscriber(s)",
    "Itinerary.builder.create.create_client": "Create Subscriber",
    "Itinerary.builder.create.create": "Create",
    "Itinerary.builder.create.cancel": "Cancel",
    "Itinerary.builder.create.message_your_iti_will_have": "Your travel asset will have",
    "Itinerary.builder.create.message_days_instead_of": "days instead of",
    "Itinerary.builder.create.message_you_have_days": "You have template items on days that will be left out. Change the dates of your travel asset so it has at least",
    "Itinerary.builder.create.message_choose_another_template": "days or choose another template.",
    "Itinerary.builder.create.message_details_will_be_lost": "Any details on those extra days will be lost.",
    "Itinerary.builder.templatecreate.title": "Create New Template",
    "Itinerary.builder.templatecreate.itinerary_language": "Language to use in the travel asset",
    "Itinerary.builder.templatecreate.select_days_keep_template_warning": "Select the days to keep from the template. {numDays} MAX!",
    "Itinerary.builder.templatecreate.select_days_error": "Your travel asset will have {numDays} days. Select at most {numDays} days from the template.",
    "Itinerary.builder.templatecreate.name": "Name",
    "Itinerary.builder.templatecreate.name_size": "The max size is 255.",
    "Itinerary.builder.templatecreate.date_format": "Date format",
    "Itinerary.builder.templatecreate.use_itinerary_template": "Use Travel asset as template (optional)",
    "Itinerary.builder.templatecreate.open_in_new_tab_tooltip": "Open in new tab",
    "Itinerary.builder.templatecreate.select_itinerary": "Select Travel asset",
    "Itinerary.builder.templatecreate.field_required": "This field is required.",
    "Itinerary.builder.templatecreate.days": "Number of days",
    "Itinerary.builder.templatecreate.num_days_minvalue": "Minimum value is 1",
    "Itinerary.builder.templatecreate.countries": "Countries",
    "Itinerary.builder.templatecreate.select_countries": "Select countries",
    "Itinerary.builder.templatecreate.currency": "Currency",
    "Itinerary.builder.templatecreate.currency_select": "Select currency",
    "Itinerary.builder.templatecreate.create": "Create",
    "Itinerary.builder.templatecreate.cancel": "Cancel",
    "Itinerary.builder.templatecreate.message_your_iti_will_have": "Your tempplate will have",
    "Itinerary.builder.templatecreate.message_days_instead_of": "days instead of",
    "Itinerary.builder.templatecreate.message_you_have_days": "You have template items on days that will be left out. Change the number of days of your template so it has at least",
    "Itinerary.builder.templatecreate.message_choose_another_template": "days or choose another template.",
    "Itinerary.builder.templatecreate.message_details_will_be_lost": "Any details on those extra days will be lost.",
    "Itinerary.builder.detail.not_visible_mobile": "This Travel asset is not visible on the mobile app since its status is Draft.",
    "Itinerary.builder.detail.itinerary": "Travel asset",
    "Itinerary.builder.detail.template_title": "Travel asset template",
    "Itinerary.builder.detail.create_copy_btn": "Copy",
    "Itinerary.builder.detail.create_copy_tooltip": "Copy into new template or travel asset",
    "Itinerary.builder.detail.copy_to_template_btn": "Copy to new template",
    "Itinerary.builder.detail.copy_to_itinerary_btn": "Copy to new travel asset",
    "Itinerary.builder.detail.click_edit": "Click to edit",
    "Itinerary.builder.detail.manager": "Manager",
    "Itinerary.builder.detail.published": "Published",
    "Itinerary.builder.detail.published_tooltip": "Published on mobile app",
    "Itinerary.builder.detail.notpublished": "Not published",
    "Itinerary.builder.detail.notpublished_tooltip": "Not published on mobile app",

    "Itinerary.builder.detail.showInterestButton": "Showing Request Buttons",
    "Itinerary.builder.detail.showInterestButtonToolTip": "Shows Request Buttons on Optional Activity Items in the Mobile App",
    "Itinerary.builder.detail.showInterestButtonNot": "Not Showing Request Buttons",
    "Itinerary.builder.detail.showInterestButtonNotToolTip": "Don't Show Request Buttons on Optional Activity Items in the Mobile App",

    "Itinerary.builder.detail.change_manager_tooltip": "Change trip manager",
    "Itinerary.builder.detail.status": "Status",
    "Itinerary.builder.detail.status_change": "Change status",
    "Itinerary.builder.detail.draft": "Draft",
    "Itinerary.builder.detail.sold": "Ready",
    "Itinerary.builder.detail.send_by_email": "Send by email",
    "Itinerary.builder.detail.settings": "Settings",
    "Itinerary.builder.detail.documentation": "Documentation",
    "Itinerary.builder.detail.trip": "Trip",
    "Itinerary.builder.detail.map": "Map",
    "Itinerary.builder.detail.gpxroutes": "Routes",
    "Itinerary.builder.detail.save": "Save",
    "Itinerary.builder.detail.last_saved": "Last saved",
    "Itinerary.builder.detail.undo": "Undo",
    "Itinerary.builder.detail.redo": "Redo",
    "Itinerary.builder.detail.included": "Included",
    "Itinerary.builder.detail.optional": "Optional",
    "Itinerary.builder.detail.name_updated": "Name updated",
    "Itinerary.builder.detail.iti_updated": "Travel asset updated",
    "Itinerary.builder.detail.export_pdf": "Export to PDF",
    "Itinerary.builder.detail.export_pdf_full": "Full size PDF (high quality images)",
    "Itinerary.builder.detail.export_pdf_medium": "Medium size PDF (medium quality images)",
    "Itinerary.builder.detail.export_pdf_low": "Reduced size PDF (low quality images)",
    "Itinerary.builder.documents.tab_help": "Upload any useful materials in pdf, jpeg or png format here, and they will be available for download by subscribers of your tour in the \"Docs & Links\" section",
    "Itinerary.builder.documents.upload_document": "Upload document",
    "Itinerary.builder.documents.filename": "Filename",
    "Itinerary.builder.documents.description": "Description",
    "Itinerary.builder.documents.filename_mandatory": "Filename is mandatory",
    "Itinerary.builder.documents.filename_edited": "The file was edited",
    "Itinerary.builder.documents.are_you_sure": "Are you sure?",
    "Itinerary.builder.documents.are_you_sure_delete": "Are you sure you to delete",
    "Itinerary.builder.documents.yes": "Yes",
    "Itinerary.builder.documents.file_was_deleted": "The file was deleted",
    "Itinerary.builder.map.full_trip_btn": "Full Trip",
    "Itinerary.builder.map.full_trip_tooltip": "Click to switch to day by day map",
    "Itinerary.builder.map.day_trip_btn": "Day {day}",
    "Itinerary.builder.map.day_trip_tooltip": "Click to switch to full trip map",
    "Itinerary.builder.gpx.tab_help": "Download GPX files here to add information about routes and landmarks on the map of your tour. To make it visible to your subscribers, mark the necessary routes and landmarks in the checkboxes on the left and assign them the desired days of the tour",
    "Itinerary.builder.gpx.upload_file": "Upload GPX File",
    "Itinerary.builder.gpx.routes_heading": "Routes",
    "Itinerary.builder.gpx.waypoints_heading": "Waypoints",
    "Itinerary.builder.gpx.set_day": "Set Day",
    "Itinerary.builder.gpx.delete": "Delete",
    "Itinerary.builder.gpx.all_label": "ALL",
    "Itinerary.builder.gpx.none_label": "NONE",
    "Itinerary.builder.gpx.name_col": "Name",
    "Itinerary.builder.gpx.day_col": "Day",
    "Itinerary.builder.gpx.routes_are_you_sure_title": "Are you sure?",
    "Itinerary.builder.gpx.routes_are_you_sure_msg": "Are you sure you to delete the selected routes?",
    "Itinerary.builder.gpx.routes_delete_success": "Routes deleted",
    "Itinerary.builder.gpx.waypoints_are_you_sure_title": "Are you sure?",
    "Itinerary.builder.gpx.waypoints_are_you_sure_msg": "Are you sure you to delete the selected points of interest?",
    "Itinerary.builder.gpx.waypoints_delete_success": "Points of interest deleted",
    "Itinerary.builder.gpx.day_form": "Day",
    "Itinerary.builder.ItineraryList.itineraries": "Travel assets",
    "Itinerary.builder.ItineraryList.create_new": "Create new",
    "Itinerary.builder.ItineraryList.name": "Name",
    "Itinerary.builder.ItineraryList.countries": "Countries",
    "Itinerary.builder.ItineraryList.days": "Days",
    "Itinerary.builder.ItineraryList.clients": "Subscribers",
    "Itinerary.builder.ItineraryList.manager": "Manager",
    "Itinerary.builder.ItineraryList.created": "Created",
    "Itinerary.builder.ItineraryList.updated": "Updated",
    "Itinerary.builder.ItineraryList.published": "Published",
    "Itinerary.builder.ItineraryList.status": "Status",
    "Itinerary.builder.ItineraryList.actions": "Actions",
    "Itinerary.builder.ItineraryList.delete_label": "Delete",
    "Itinerary.builder.ItineraryList.message_once_removed": "Once removed",
    "Itinerary.builder.ItineraryList.message_will_no_longer": "will no longer be available for the subscriber!",
    "Itinerary.builder.ItineraryList.are_you_sure": "Are you sure?",
    "Itinerary.builder.ItineraryList.itinerary_was_deleted": "The travel asset was deleted",
    "Itinerary.builder.TemplateList.templates": "Templates",
    "Itinerary.builder.TemplateList.create_new": "Create new",
    "Itinerary.builder.TemplateList.name": "Name",
    "Itinerary.builder.TemplateList.countries": "Countries",
    "Itinerary.builder.TemplateList.days": "Days",
    "Itinerary.builder.TemplateList.clients": "Subscribers",
    "Itinerary.builder.TemplateList.manager": "Manager",
    "Itinerary.builder.TemplateList.created": "Created",
    "Itinerary.builder.TemplateList.updated": "Updated",
    "Itinerary.builder.TemplateList.published": "Published",
    "Itinerary.builder.TemplateList.status": "Status",
    "Itinerary.builder.TemplateList.actions": "Actions",
    "Itinerary.builder.TemplateList.delete_label": "Delete",
    "Itinerary.builder.TemplateList.message_once_removed": "Once removed",
    "Itinerary.builder.TemplateList.message_will_no_longer": "will no longer be available for the subscriber!",
    "Itinerary.builder.TemplateList.are_you_sure": "Are you sure?",
    "Itinerary.builder.TemplateList.template_was_deleted": "The template was deleted",
    "Itinerary.builder.settings.itinerary_language": "Language to use in the travel asset",
    "Itinerary.builder.settings.date_format": "Date format",
    "Itinerary.builder.settings.dates": "Dates",
    "Itinerary.builder.settings.start_date": "Start date",
    "Itinerary.builder.settings.end_date": "End date",
    "Itinerary.builder.settings.days": "Number of days",
    "Itinerary.builder.settings.field_required": "This field is required.",
    "Itinerary.builder.settings.num_days_minvalue": "Minimum value is 1",
    "Itinerary.builder.settings.countries": "Countries",
    "Itinerary.builder.settings.countries_select": "Select countries",
    "Itinerary.builder.settings.currency": "Currency",
    "Itinerary.builder.settings.currency_select": "Select currency",
    "Itinerary.builder.settings.cost": "Price",
    "Itinerary.builder.settings.duration": "Duration",
    "Itinerary.builder.settings.trip_type": "Travel asset type",
    "Itinerary.builder.settings.clients": "Subscribers",
    "Itinerary.builder.settings.clients_select": "Select subscriber(s)",
    "Itinerary.builder.settings.create_client": "Create Subscriber",
    "Itinerary.builder.settings.message_cant_change_trip_dates": "Can't change the trip dates",
    "Itinerary.builder.settings.message_your_iti_will_have": "Your travel asset will have",
    "Itinerary.builder.settings.message_days_instead_of": "days instead of",
    "Itinerary.builder.settings.message_days_will_be_left_out": "You have items on days that will be left out. Change them according to the new days first.",
    "Itinerary.builder.settings.message_details_may_lost": "Any details on those extra days will be lost.",
    "Itinerary.builder.settings.message_continue": "Continue",
    "Itinerary.builder.library.library": "Library",
    "Itinerary.builder.library.title": "Title",
    "Itinerary.builder.library.click_to_change_name": "Click to change item name",
    "Itinerary.builder.library.tags": "Tags",
    "Itinerary.builder.library.tag_select_add": "Select tag(s) or add new",
    "Itinerary.builder.library.cancel": "Cancel",
    "Itinerary.builder.library.save": "Save",
    "Itinerary.builder.library.item_update": "Library Item updated",
    "Itinerary.builder.library.name_address": "Name/Address",
    "Itinerary.builder.library.tag_select": "Select tag(s)",
    "Itinerary.builder.library.type": "Type",
    "Itinerary.builder.library.status": "Status",
    "Itinerary.builder.library.active": "Active",
    "Itinerary.builder.library.inactive": "Inactive",
    "Itinerary.builder.library.name": "Name",
    "Itinerary.builder.library.address": "Address",
    "Itinerary.builder.library.created_at": "Created at",
    "Itinerary.builder.library.actions": "Actions",
    "Itinerary.builder.library.message_deactivate_wont_be_availabel": "Deactivate (It won't be available to use anymore)",
    "Itinerary.builder.library.item_deactivated": "Item deactivated",
    "Itinerary.builder.library.accommodation": "Accommodation",
    "Itinerary.builder.library.meals": "Meals",
    "Itinerary.builder.library.content": "Content",
    "Itinerary.builder.library.photos": "Photos",
    "Itinerary.builder.library.sections": "Sections",
    "Itinerary.builder.library.caption_location": "Caption/Location",
    "Itinerary.builder.library.click_to_change_caption": "Click to change caption",
    "Itinerary.builder.library.write_caption_here": "Write image caption here",
    "Itinerary.builder.library.click_to_change_tags": "Click to change tags",
    "Itinerary.builder.library.load_more": "Load more",
    "Itinerary.chat.send": "Send",
    "Itinerary.chat.your_message": "Your Message",
    "Itinerary.modal.UploadGpx.title": "Upload GPX File",
    "Itinerary.modal.UploadGpx.close": "Close",
    "Itinerary.modal.UploadGpx.dropzone_invalid_file_type": "Only .gpx files are accepted",
    "Itinerary.modal.ImageUpload.pixabay_free": "Pixabay free high-resolution photos",
    "Itinerary.modal.ImageUpload.library_photos": "Library photos",
    "Itinerary.modal.ImageUpload.upload": "Upload",
    "Itinerary.modal.ImageUpload.search_free_photos": "Search free high-resolution photos",
    "Itinerary.modal.ImageUpload.photos_by": "Photos by",
    "Itinerary.modal.ImageUpload.no_photos_found": "No photos found",
    "Itinerary.modal.ImageUpload.load_more": "Load more",
    "Itinerary.modal.ImageUpload.tags": "Tags",
    "Itinerary.modal.ImageUpload.select_tags": "Select tag(s)",
    "Itinerary.modal.ImageUpload.caption_location": "Caption/Location",
    "Itinerary.modal.ImageUpload.click_to_add": "Click to add",
    "Itinerary.modal.ImageUpload.select_tags_or_add": "Select tag(s) or add new",
    "Itinerary.modal.ImageUpload.photo_already_added": "Photo already added",
    "Itinerary.modal.ImageUpload.write_something_first": "Write something first...",
    "Itinerary.modal.ImageUpload.photo_added": "Photo added",
    "Itinerary.modal.ImageUpload.unsplash": "Unsplash",
    "Itinerary.modal.ImageUpload.search_instead": "Search photos instead",
    "Itinerary.modal.ImageUpload.upload_instead": "Upload your photos instead",
    "Itinerary.modal.ImageUpload.pixabay": "Pixabay",
    "Itinerary.modal.BuilderAddItem.tags": "Tags",
    "Itinerary.modal.BuilderAddItem.select_tags": "Select tag(s)",
    "Itinerary.modal.BuilderAddItem.type_to_search": "Type to search",
    "Itinerary.modal.BuilderAddItem.or_create_from_scratch": "Or create a new one from scratch",
    "Itinerary.modal.BuilderAddItem.generic_item": "Generic Item",
    "Itinerary.modal.BuilderAddItem.generic_item_description": "A title, a text area and a bullet point list",
    "Itinerary.modal.BuilderAddItem.tour_cover": "Tour Cover",
    "Itinerary.modal.BuilderAddItem.tour_cover_description": "The tour title, one line description and the cover image. Should be added to the first section/page",
    "Itinerary.modal.BuilderAddItem.tour_name": "Tour Name",
    "Itinerary.modal.BuilderAddItem.tour_name_description": "A title, a one line description and the reference number",
    "Itinerary.modal.BuilderAddItem.client_information": "Subscriber Information",
    "Itinerary.modal.BuilderAddItem.client_information_description": "Number of people, names and emails",
    "Itinerary.modal.BuilderAddItem.trip_highlights": "Trip Highlights",
    "Itinerary.modal.BuilderAddItem.trip_highlights_description": "A title and a bullet list with the trip highlights",
    "Itinerary.modal.BuilderAddItem.trip_description": "Trip Description",
    "Itinerary.modal.BuilderAddItem.trip_description_description": "A title and text area",
    "Itinerary.modal.BuilderAddItem.trip_dates": "Trip Dates",
    "Itinerary.modal.BuilderAddItem.trip_dates_description": "A title and the trip dates",
    "Itinerary.modal.BuilderAddItem.organizer_details": "Organizer Details",
    "Itinerary.modal.BuilderAddItem.organizer_details_description": "The agency and trip manager details",
    "Itinerary.modal.BuilderAddItem.destination_info": "Destination Info",
    "Itinerary.modal.BuilderAddItem.destination_info_description": "A title and a text area",
    "Itinerary.modal.BuilderAddItem.know_before_you_go": "Know before you go",
    "Itinerary.modal.BuilderAddItem.know_before_you_go_description": "Two bullet lists: one with the packing list and the other with important info to know before the trip",
    "Itinerary.modal.BuilderAddItem.description_of_day": "Description of the day",
    "Itinerary.modal.BuilderAddItem.description_of_day_description": "A title and two text areas. Describe the day and add some historical details",
    "Itinerary.modal.BuilderAddItem.activity_included": "Activities / Services (included)",
    "Itinerary.modal.BuilderAddItem.activity_included_description": "List of included activites and services",
    "Itinerary.modal.BuilderAddItem.activity_optional": "Generic item",
    "Itinerary.modal.BuilderAddItem.activity_optional_description": "List of activites and services",
    "Itinerary.modal.BuilderAddItem.hotel": "Hotel",
    "Itinerary.modal.BuilderAddItem.hotel_description": "A hotel/B&B/AirBnB/etc",
    "Itinerary.modal.BuilderAddItem.breakfast": "Breakfast",
    "Itinerary.modal.BuilderAddItem.breakfast_description": "A breakfast location (restaurant/etc)",
    "Itinerary.modal.BuilderAddItem.place": "Place",
    "Itinerary.modal.BuilderAddItem.place_description": "A place location (restaurant/etc)",
    "Itinerary.modal.BuilderAddItem.lunch": "Lunch",
    "Itinerary.modal.BuilderAddItem.lunch_description": "A lunch location (restaurant/etc)",
    "Itinerary.modal.BuilderAddItem.dinner": "Dinner",
    "Itinerary.modal.BuilderAddItem.dinner_description": "A dinner location (restaurant/etc)",
    "Itinerary.modal.BuilderAddItem.flight": "Flight",
    "Itinerary.modal.BuilderAddItem.flight_description": "Flight details with  start/end locations and times",
    "Itinerary.modal.BuilderAddItem.train": "Train",
    "Itinerary.modal.BuilderAddItem.train_description": "Train trip details with start/end locations and times",
    "Itinerary.modal.BuilderAddItem.transfer": "Transfer",
    "Itinerary.modal.BuilderAddItem.transfer_description": "Transfer details with start/end locations and times",
    "Itinerary.modal.BuilderAddItem.carrent": "Car Rental",
    "Itinerary.modal.BuilderAddItem.carrent_description": "Car Rental details with start/end locations and times",
    "Itinerary.modal.BuilderAddItem.price_included": "Price (included)",
    "Itinerary.modal.BuilderAddItem.price_included_description": "Tour price details (total price per person/group/single supplement)",
    "Itinerary.modal.BuilderAddItem.price_optional": "Price (optional)",
    "Itinerary.modal.BuilderAddItem.price_optional_description": "Tour price details for the options (total price per person/group/single supplement)",
    "Itinerary.modal.BuilderAddItem.whatsincluded": "Whats Included",
    "Itinerary.modal.BuilderAddItem.whatsincluded_description": "A title, a summary table with flights, meals, hotels, activities and their dates and another table with the optional activities and their prices.",
    "Itinerary.modal.BuilderAddItem.nextstep": "Next Steps",
    "Itinerary.modal.BuilderAddItem.nextstep_description": "The deposit value, when to pay and contacts",
    "Itinerary.modal.BuilderAddItem.cancelation_policy": "Cancelation Policy",
    "Itinerary.modal.BuilderAddItem.cancelation_policy_description": "A title, text area and a table with the cancelation penalties",
    "Itinerary.modal.BuilderAddItem.ratings": "Ratings",
    "Itinerary.modal.BuilderAddItem.ratings_description": "Rate by criteria from 1 to 5 stars",
    "Itinerary.modal.BuilderAddItem.carousel": "Photo gallery",
    "Itinerary.modal.BuilderAddItem.carousel_description": "Image carousel",
    "Itinerary.modal.BuilderAddItem.audio": "Audio file",
    "Itinerary.modal.BuilderAddItem.audio_description": "Upload audio file",
    "Itinerary.modal.BuilderAddItem.video_file": "Video file",
    "Itinerary.modal.BuilderAddItem.video_file_description": "Upload video file",
    "Itinerary.modal.BuilderAddItem.faq": "Frequently Asked Questions (FAQ)",
    "Itinerary.modal.Step2CarRental.dates": "Dates",
    "Itinerary.modal.Step2CarRental.start_date": "Start date",
    "Itinerary.modal.Step2CarRental.end_date": "End date",
    "Itinerary.modal.Step2CarRental.please_select": "Please select the dates.",
    "Itinerary.modal.Step2CarRental.cancel": "Cancel",
    "Itinerary.modal.Step2CarRental.add": "Add",
    "Itinerary.modal.Step2Sleep.dates": "Dates",
    "Itinerary.modal.Step2Sleep.start_date": "Start date",
    "Itinerary.modal.Step2Sleep.end_date": "End date",
    "Itinerary.modal.Step2Sleep.please_select": "Please select the dates.",
    "Itinerary.modal.Step2Sleep.autocomplete_google": "Autocomplete details from Google",
    "Itinerary.modal.Step2Sleep.type_to_search": "Type to search",
    "Itinerary.modal.Step2Sleep.cancel": "Cancel",
    "Itinerary.modal.Step2Sleep.add": "Add",
    "Itinerary.modal.Step2Sleep.add_hotel": "Add Hotel",
    "Itinerary.modal.Step2Sleep.add_item": "Add Item",
    "Itinerary.modal.ChangeDates.change_dates": "Change Dates",
    "Itinerary.modal.ChangeDates.dates": "Dates",
    "Itinerary.modal.ChangeDates.start_date": "Start date",
    "Itinerary.modal.ChangeDates.end_date": "End date",
    "Itinerary.modal.ChangeDates.select_dates": "Please select the dates.",
    "Itinerary.modal.ChangeDates.cancel": "Cancel",
    "Itinerary.modal.ChangeManager.title": "Change Trip Manager",
    "Itinerary.modal.ChangeManager.manager": "Manager",
    "Itinerary.modal.ChangeManager.save": "Save",
    "Itinerary.modal.ChangeManager.load_manager_list_failed": "Failed to load manager list",
    "Itinerary.modal.ChangeManager.updated": "Trip manager updated",
    "Itinerary.modal.CoverImageUpload.set_cover_photo": "Set cover photo",
    "Itinerary.modal.CoverImageUpload.close": "Close",
    "Itinerary.modal.ItemMap.location": "Location",
    "Itinerary.modal.ItemMap.type_address_to_search_and_change": "Type an address to search and change",
    "Itinerary.modal.ItemMap.click_drag_change_location": "Click on the map or drag the marker to change the location",
    "Itinerary.modal.ItemMap.close": "Close",
    "Itinerary.modal.ItemMap.cancel": "Cancel",
    "Itinerary.modal.ItemMap.update_location": "Update location",
    "Itinerary.modal.ItinerarySendEmail.send_itinerary_by_email_pdf": "Send travel asset by email (PDF)",
    "Itinerary.modal.ItinerarySendEmail.clients": "Subscribers *",
    "Itinerary.modal.ItinerarySendEmail.clients_select": "Select subscriber(s)",
    "Itinerary.modal.ItinerarySendEmail.text": "Text *",
    "Itinerary.modal.ItinerarySendEmail.field_required": "This field is required",
    "Itinerary.modal.ItinerarySendEmail.send_copy_to_me": "Send a copy to myself",
    "Itinerary.modal.ItinerarySendEmail.send": "Send",
    "Itinerary.modal.ItinerarySendEmail.cancel": "Cancel",
    "Itinerary.modal.ItinerarySendEmail.email_sent": "Email sent",
    "Itinerary.modal.ItinerarySendEmail.failed_email_sent": "Failed sending email",
    "Itinerary.modal.MealAddAlternative.add_meal_alternative": "Add Meal Alternative",
    "Itinerary.modal.MealAddAlternative.add_from_library": "Add from your library",
    "Itinerary.modal.MealAddAlternative.tags": "Tags",
    "Itinerary.modal.MealAddAlternative.select_tag": "Select tag(s)",
    "Itinerary.modal.MealAddAlternative.type_to_search": "Type to search",
    "Itinerary.modal.MealAddAlternative.create_from_scratch": "Or create a new one from scratch",
    "Itinerary.modal.PlacesAutocomplete.autocomplete_info": "Autocomplete information",
    "Itinerary.modal.PlacesAutocomplete.type_to_search": "Type to search",
    "Itinerary.modal.PlacesAutocomplete.cancel": "Cancel",
    "Itinerary.modal.PlacesAutocomplete.update_info": "Update information",
    "Itinerary.modal.ReorderSectionItems.title": "Reorder Items",
    "Itinerary.modal.ReorderSectionItems.reorder": "Drag and drop the items to reorder them",
    "Itinerary.modal.ReorderSectionItems.close": "Close",
    "Itinerary.modal.SaveToLibrary.title": "Save Item to Library",
    "Itinerary.modal.SaveToLibrary.name": "Name",
    "Itinerary.modal.SaveToLibrary.name_description": "This is the name the item will have on your agency library so you can find it later.",
    "Itinerary.modal.SaveToLibrary.name_required": "This field is required.",
    "Itinerary.modal.SaveToLibrary.name_size": "The max size is 255.",
    "Itinerary.modal.SaveToLibrary.tags": "Tags",
    "Itinerary.modal.SaveToLibrary.tags_select": "Select tag(s) or add new",
    "Itinerary.modal.SaveToLibrary.cancel": "Cancel",
    "Itinerary.modal.SaveToLibrary.save": "Save to Library",
    "Itinerary.modal.SaveToLibrary.update": "Update to Library",
    "Itinerary.modal.SaveToLibrary.saved": "Item saved to library",
    "Itinerary.modal.SaveToLibrary.alreadyexists": "Already exists on the Library, the changes will be saved on top. Check box to create new library Item instead:",
    "Itinerary.modal.SleepAddAlternative.title": "Add Hotel Alternative",
    "Itinerary.modal.SleepAddAlternative.add_from_library": "Add from your library",
    "Itinerary.modal.SleepAddAlternative.tags": "Tags",
    "Itinerary.modal.SleepAddAlternative.select_tags": "Select tag(s)",
    "Itinerary.modal.SleepAddAlternative.type_to_search": "Type to search",
    "Itinerary.modal.SleepAddAlternative.create_from_scratch": "Or create a new one from scratch",
    "Itinerary.modal.TransportationMap.title": "Location",
    "Itinerary.modal.TransportationMap.type_to_search": "Type an address to search and change",
    "Itinerary.modal.TransportationMap.click_on_map_or_drag": "Click on the map or drag the marker to change the location",
    "Itinerary.modal.TransportationMap.cancel": "Cancel",
    "Itinerary.modal.TransportationMap.update_location": "Update location",
    "Itinerary.modal.UploadDocument.title": "Upload document",
    "Itinerary.modal.UploadDocument.close": "Close",
    "Itinerary.TripActivity.optional_title": "Subtitle",
    "Itinerary.TripActivity.included_title": "Included activity title",
    "Itinerary.TripActivity.optional_description": "Description",
    "Itinerary.TripActivity.included_description": "Included activity description",
    "Itinerary.TripActivity.tooltip.move_to_another_section": "Move to other section",
    "Itinerary.TripActivity.tooltip.show_on_map": "Show on map",
    "Itinerary.TripActivity.tooltip.set_on_map": "Set address/location on map",
    "Itinerary.TripActivity.tooltip.address": "Address",
    "Itinerary.TripActivity.tooltip.duration": "Duration",
    "Itinerary.TripActivity.tooltip.language": "Language",
    "Itinerary.TripActivity.tooltip.price": "Price",
    "Itinerary.TripActivity.tooltip.price_type": "Price type",
    "Itinerary.TripActivity.tooltip.local_contact": "Local contact",
    "Itinerary.TripActivity.tooltip.name": "Name",
    "Itinerary.TripActivity.tooltip.phone": "Phone",
    "Itinerary.TripActivity.tooltip.email": "Email",
    "Itinerary.TripActivity.tooltip.start_time": "Start time",
    "Itinerary.TripActivity.tooltip.caption_here": "Write image caption here",
    "Itinerary.TripActivity.tooltip.remove_activity": "Remove activity",
    "Itinerary.TripActivity.button.add_images": "Add Images",
    "Itinerary.TripActivity.button.add_activity": "Add activity",
    "Itinerary.TripActivity.price_types.free": "Free",
    "Itinerary.TripActivity.price_types.per_person": "Per Person (p.p.)",
    "Itinerary.TripActivity.price_types.per_group": "Per Group (p.g.)",
    "Itinerary.TripActivity.messages.delete_item_title": "Are you sure?",
    "Itinerary.TripActivity.messages.delete_item_message": "Remove this activity?",
    "Itinerary.TripActivity.messages.delete_item_button": "Remove",
    "Itinerary.TripCancelationPolicy.tooltip.move_to_another_section": "Move to other section",
    "Itinerary.TripCancelationPolicy.tooltip.description": "Description",
    "Itinerary.TripCancelationPolicy.tooltip.drag_to_reorder": "Drag to reorder",
    "Itinerary.TripCancelationPolicy.tooltip.days_60_30": "60 - 30 days",
    "Itinerary.TripCarRental.tooltip.change_rental_dates": "Change rental dates",
    "Itinerary.TripCarRental.tooltip.company": "Company",
    "Itinerary.TripCarRental.tooltip.pickup_location": "Pickup location",
    "Itinerary.TripCarRental.tooltip.pickup_time": "Pickup time",
    "Itinerary.TripCarRental.tooltip.phone": "Phone",
    "Itinerary.TripCarRental.tooltip.dropoff_location": "Dropoff location",
    "Itinerary.TripCarRental.tooltip.dropoff_time": "Dropoff time",
    "Itinerary.TripCarRental.tooltip.reference_number": "Reference Number",
    "Itinerary.TripCarRental.tooltip.car_model": "Car model",
    "Itinerary.TripCarRental.tooltip.details": "Details",
    "Itinerary.TripCarRental.tooltip.set_addres_on_map": "Set address/location on map",
    "Itinerary.TripClientInfo.tooltip.client_details": "Subscriber details",
    "Itinerary.TripClientInfo.tooltip.guests": "Guests",
    "Itinerary.TripClientInfo.tooltip.number_guests": "Number of guests",
    "Itinerary.TripClientInfo.tooltip.guest_name": "Guest name",
    "Itinerary.TripClientInfo.tooltip.guest_email": "Guest email",
    "Itinerary.TripCompanyInfo.tooltip.organizer_details": "Organizer details",
    "Itinerary.TripCompanyInfo.tooltip.trip_manager": "Trip Manager",
    "Itinerary.TripCompanyInfo.tooltip.local_trip_manager": "Local Trip Manager",
    "Itinerary.TripCompanyInfo.tooltip.name": "Name",
    "Itinerary.TripCompanyInfo.tooltip.email": "Email",
    "Itinerary.TripCompanyInfo.tooltip.phone": "Phone",
    "Itinerary.TripCompanyInfo.tooltip.emergency_contacts": "Emergency Contacts",
    "Itinerary.TripCompanyInfo.tooltip.description": "Description",
    "Itinerary.TripCompanyInfo.messages.manager_profile_pic": "The manager needs to set his picture on his profile (clicking on his name on top right corner)",
    "Itinerary.TripCompanyInfo.messages.failed_load_data": "Failed loading company info",
    "Itinerary.TripCover.tooltip.set_cover": "Set Cover Photo",
    "Itinerary.TripCover.tooltip.tour_name": "Tour name/title",
    "Itinerary.TripCover.tooltip.places_to_visit": "Places/countries to visit",
    "Itinerary.TripDates.tooltip.trip_dates": "Trip Dates",
    "Itinerary.TripDescription.tooltip.type_something_here": "Type something here...",
    "Itinerary.TripDestinationInfo.tooltip.places_to_visit": "Places of visit",
    "Itinerary.TripDestinationInfo.tooltip.type_something_here": "Type something here...",
    "Itinerary.TripDestinationInfo.tooltip.emergency_information": "Emergency Information",
    "Itinerary.TripDestinationInfo.tooltip.name": "Name",
    "Itinerary.TripDestinationInfo.tooltip.phone": "Phone",
    "Itinerary.TripDestinationInfo.tooltip.description": "Description",
    "Itinerary.TripGenericItem.tooltip.title": "Title",
    "Itinerary.TripGenericItem.tooltip.details_placeholder": "Type something here...",
    "Itinerary.TripGenericItem.tooltip.bullet_placeholder": "Type something here...",
    "Itinerary.TripHighlights.tooltip.trip_highlights": "Trip highlight",
    "Itinerary.TripKnowBeforeGo.tooltip.pack_list": "Packing List",
    "Itinerary.TripKnowBeforeGo.tooltip.pack_item": "Packing item",
    "Itinerary.TripKnowBeforeGo.tooltip.important_to_know": "Important to know before you go",
    "Itinerary.TripKnowBeforeGo.tooltip.describe_item_here": "Describe item here",
    "Itinerary.TripMeal.tooltip.title": "Title",
    "Itinerary.TripMeal.tooltip.name": "Name",
    "Itinerary.TripMeal.tooltip.google_rating": "Google rating",
    "Itinerary.TripMeal.tooltip.description": "Description",
    "Itinerary.TripMeal.tooltip.address": "Address",
    "Itinerary.TripMeal.tooltip.phone": "Phone",
    "Itinerary.TripMeal.tooltip.open_web": "Open Website",
    "Itinerary.TripMeal.tooltip.website": "Website",
    "Itinerary.TripMeal.tooltip.alternatives": "Alternatives",
    "Itinerary.TripMeal.tooltip.all_alternatives": "Add Alternatives",
    "Itinerary.TripMeal.alternatives.set_as_main": "Set this as main Option",
    "Itinerary.TripMeal.alternatives.title": "Title",
    "Itinerary.TripMeal.alternatives.name": "name",
    "Itinerary.TripNextSteps.tooltip.deposit": "Deposit",
    "Itinerary.TripNextSteps.tooltip.when_to_be_paid": "When to be paid",
    "Itinerary.TripNextSteps.tooltip.email": "Email",
    "Itinerary.TripNextSteps.tooltip.phone": "Phone",
    "Itinerary.TripPoi.tooltip.description_of_day": "Description of the day",
    "Itinerary.TripPoi.tooltip.description_of_place": "Description of the place",
    "Itinerary.TripPlace.type.accommodation": "Accommodation",
    "Itinerary.TripPlace.type.restaurant": "Restaurant",
    "Itinerary.TripPlace.type.cafe": "Cafe",
    "Itinerary.TripPlace.type.drink": "Drink",
    "Itinerary.TripPlace.type.sight": "Sight, museum, attraction",
    "Itinerary.TripPlace.type.meeting_point": "Meeting point or entry point",
    "Itinerary.TripPlace.type.starting_point": "Starting point",
    "Itinerary.TripPlace.type.finish_point": "Finish point",
    "Itinerary.TripPlace.type.stop_along_route": "Stop along route",
    "Itinerary.TripPlace.type.other": "Other point",
    "Itinerary.TripPrice.tooltip.price_per_person": "Price per person",
    "Itinerary.TripPrice.tooltip.price_per_group": "Price per group",
    "Itinerary.TripPrice.tooltip.per_person": "per person",
    "Itinerary.TripPrice.tooltip.per_group": "per group",
    "Itinerary.TripPrice.tooltip.adult": "Adult",
    "Itinerary.TripPrice.tooltip.child": "Child",
    "Itinerary.TripPrice.tooltip.single_price": "Single price",
    "Itinerary.TripPrice.tooltip.single_supplement": "single supplement",
    "Itinerary.TripPrice.tooltip.drag_to_reorder": "Drag to reorder",
    "Itinerary.TripPrice.tooltip.num_persons_placeholder": "Up to x persons",
    "Itinerary.TripPrice.tooltip.price_per_person_placeholder": "Price",
    "Itinerary.TripSleep.tooltip.autocomplete_hotel": "Autocomplete hotel information",
    "Itinerary.TripSleep.tooltip.change_dates": "Change hotel dates",
    "Itinerary.TripSleep.tooltip.hotel_name": "Hotel name",
    "Itinerary.TripSleep.tooltip.type": "Type",
    "Itinerary.TripSleep.tooltip.select_hotel_type": "Select hotel type or write new",
    "Itinerary.TripSleep.tooltip.add_hotel_type": "Add new hotel type",
    "Itinerary.TripSleep.tooltip.description": "Description",
    "Itinerary.TripSleep.tooltip.address": "Address",
    "Itinerary.TripSleep.tooltip.phone": "Phone",
    "Itinerary.TripSleep.tooltip.check_in": "Check-in",
    "Itinerary.TripSleep.tooltip.check_out": "Check-out",
    "Itinerary.TripSleep.tooltip.check_in_time": "Check-in time",
    "Itinerary.TripSleep.tooltip.check_out_time": "Check-out time",
    "Itinerary.TripSleep.tooltip.check_out_at": "Check-out at",
    "Itinerary.TripSleep.tooltip.meal_plan": "Meal Plan",
    "Itinerary.TripSleep.tooltip.select_meal_plan": "Select meal plan type or write new",
    "Itinerary.TripSleep.tooltip.add_meal_plan": "Add new meal plan",
    "Itinerary.TripSleep.tooltip.rooms": "Rooms",
    "Itinerary.TripSleep.tooltip.room": "Room",
    "Itinerary.TripSleep.tooltip.number_rooms": "Number of rooms",
    "Itinerary.TripSleep.tooltip.change_number_rooms": "Change number of rooms",
    "Itinerary.TripSleep.tooltip.select_room_type": "Select room type or write new",
    "Itinerary.TripSleep.tooltip.add_room_type": "Add new room type",
    "Itinerary.TripSleep.tooltip.alternatives": "Alternatives",
    "Itinerary.TripSleep.tooltip.add_alternatives": "Add Alternatives",
    "Itinerary.TripSleep.tooltip.set_main_hotel": "Set this as main Hotel",
    "Itinerary.TripSleep.tooltip.night": "Night",
    "Itinerary.TripSleep.tooltip.alternative_show_on_pdf": "Show on PDF",
    "Itinerary.TripSoundcloud.placeholder": "Paste the code from “Share -> Embed” tab. Like: <iframe src...>`",
    "Itinerary.TripTourName.tooltip.tour_name": "Tour name",
    "Itinerary.TripTourName.tooltip.short_description": "Short tour description",
    "Itinerary.TripTourName.tooltip.reference": "Reference",
    "Itinerary.TripTransportation.tooltip.start_location": "Start location",
    "Itinerary.TripTransportation.tooltip.start_time": "Start time",
    "Itinerary.TripTransportation.tooltip.phone": "Phone",
    "Itinerary.TripTransportation.tooltip.duration": "Duration",
    "Itinerary.TripTransportation.tooltip.end_location": "End location",
    "Itinerary.TripTransportation.tooltip.end_time": "End time",
    "Itinerary.TripTransportation.tooltip.distance": "Distance",
    "Itinerary.TripTransportation.tooltip.distance_units": "distance units",
    "Itinerary.TripTransportation.tooltip.set_address_on_map": "Set address/location on map",
    "Itinerary.TripWhatsIncluded.table.date": "Date",
    "Itinerary.TripWhatsIncluded.table.time": "Time",
    "Itinerary.TripWhatsIncluded.table.activity": "Activity",
    "Itinerary.TripWhatsIncluded.table.airline": "Airline",
    "Itinerary.TripWhatsIncluded.table.duration": "Duration",
    "Itinerary.TripWhatsIncluded.table.type": "Type",
    "Itinerary.TripWhatsIncluded.table.departure_location": "Departure / Location",
    "Itinerary.TripWhatsIncluded.table.arrival": "Arrival",
    "Itinerary.TripWhatsIncluded.table.price": "Price",
    "Itinerary.TripWhatsIncluded.tooltip": {},
    "Itinerary.TripCarousel.title": "Title",
    "Itinerary.TripCarousel.subtitle": "Subtitle",
    "Itinerary.TripCarousel.image": "Image",
    "Itinerary.TripCarousel.tooltip.drag_to_reorder": "Drag to reorder",
    "Itinerary.TripAudio.upload": "Upload",
    "Itinerary.TripAudio.uploadSuccess": "Upload success",
    "Itinerary.TripAudio.title": "Title",
    "Itinerary.TripVideoFile.upload": "Upload",
    "Itinerary.TripVideoFile.uploadSuccess": "Upload success",
    "Itinerary.TripVideoFile.title": "Title",
    "Itinerary.builder.custom_tab_name": "Custom day label",
    "v2.Itinerary.Flight.add_another_flight": "Add another flight",
    "v2.Itinerary.Flight.connection_time": "Connection time",
    "v2.Itinerary.Flight.airline": "Airline",
    "v2.Itinerary.Flight.start_typing_airline": "Start typing airline",
    "v2.Itinerary.Flight.flight_number": "Flight number",
    "v2.Itinerary.Flight.class_desc": "Class",
    "v2.Itinerary.Flight.economy": "Economy",
    "v2.Itinerary.Flight.duration": "Duration",
    "v2.Itinerary.Flight.departure": "DEPARTURE",
    "v2.Itinerary.Flight.type_airport_to_search": "Type airport to search",
    "v2.Itinerary.Flight.start_typing_airport": "Start typing airport",
    "v2.Itinerary.Flight.time": "Time",
    "v2.Itinerary.Flight.arrival": "ARRIVAL",
    "v2.Itinerary.defaults.CLIENT_INFO_ITEM_title": "Subscriber details",
    "v2.Itinerary.defaults.ITEM_TYPE_TRIP_HIGHLIGHTS_title": "You will love",
    "v2.Itinerary.defaults.ITEM_TYPE_TRIP_DESCRIPTION_title": "Trip Description",
    "v2.Itinerary.defaults.ITEM_TYPE_TRIP_DATES_title": "Trip Dates",
    "v2.Itinerary.defaults.ITEM_TYPE_COMPANY_INFO_title": "Organizer Details",
    "v2.Itinerary.defaults.ITEM_TYPE_COMPANY_INFO_trip_manager": "Trip Manager",
    "v2.Itinerary.defaults.ITEM_TYPE_COMPANY_INFO_local_trip_manager": "Local Trip Manager",
    "v2.Itinerary.defaults.ITEM_TYPE_COMPANY_INFO_emergency_contacts": "Emergency Contacts",
    "v2.Itinerary.defaults.ITEM_TYPE_DESTINATION_INFO_places_of_visit": "Places of visit",
    "v2.Itinerary.defaults.ITEM_TYPE_DESTINATION_INFO_emergency_info": "Emergency Information",
    "v2.Itinerary.defaults.ITEM_TYPE_KNOW_BEFORE_GO_packing_list_title": "Packing List",
    "v2.Itinerary.defaults.ITEM_TYPE_KNOW_BEFORE_GO_know_before_go_title": "Important to know before you go",
    "v2.Itinerary.defaults.ITEM_TYPE_POI_title": "Description of the day",
    "v2.Itinerary.defaults.ITEM_TYPE_ACTIVITY_title": "Activity / Service",
    "v2.Itinerary.defaults.ITEM_TYPE_ACTIVITY_title_included": "(Included)",
    "v2.Itinerary.defaults.ITEM_TYPE_ACTIVITY_title_optional": "(Optional)",
    "v2.Itinerary.defaults.ITEM_TYPE_ACTIVITY_generic_title": "Generic Item",
    "v2.Itinerary.defaults.ITEM_TYPE_FLIGHT_title": "Flight",
    "v2.Itinerary.defaults.ITEM_TYPE_CAR_RENTAL_title": "Car Rental",
    "v2.Itinerary.defaults.ITEM_TYPE_MEAL_breakfast": "Breakfast",
    "v2.Itinerary.defaults.ITEM_TYPE_MEAL_lunch": "Lunch",
    "v2.Itinerary.defaults.ITEM_TYPE_MEAL_dinner": "Dinner",
    "v2.Itinerary.defaults.ITEM_TYPE_PRICE_title": "Tour price",
    "v2.Itinerary.defaults.ITEM_TYPE_PRICE_title_included": "Included",
    "v2.Itinerary.defaults.ITEM_TYPE_PRICE_title_optional": "Optional",
    "v2.Itinerary.defaults.ITEM_TYPE_PRICE_num_persons_title": "Persons",
    "v2.Itinerary.defaults.ITEM_TYPE_PRICE_price_per_person_title": "Price per person",
    "v2.Itinerary.defaults.ITEM_TYPE_WHATS_INCLUDED_title": "Whats included",
    "v2.Itinerary.defaults.ITEM_TYPE_WHATS_INCLUDED_flight_title": "Flights",
    "v2.Itinerary.defaults.ITEM_TYPE_WHATS_INCLUDED_hotel_others_title": "Hotel / Others",
    "v2.Itinerary.defaults.ITEM_TYPE_WHATS_INCLUDED_not_included_title": "Whats not included",
    "v2.Itinerary.defaults.ITEM_TYPE_NEXT_STEPS_title": "Next steps - Booking this trip",
    "v2.Itinerary.defaults.ITEM_TYPE_CANCELATION_POLICY_title": "Cancelation Policy",
    "v2.Itinerary.defaults.ITEM_TYPE_CANCELATION_POLICY_days_title": "Days prior to trip departure",
    "v2.Itinerary.defaults.ITEM_TYPE_CANCELATION_POLICY_penalty_title": "Cancelation penalty of the total tour price",
    "v2.Itinerary.defaults.SECTION_TYPE_COVER_title": "Cover",
    "v2.Itinerary.defaults.SECTION_TYPE_OTHER_tour_intro_title": "Tour Intro",
    "v2.Itinerary.defaults.SECTION_TYPE_OTHER_prices_and_conditions_title": "Prices and Conditions",
    "v2.Itinerary.defaults.ITEM_NAME_GENERIC": "Generic Item",
    "v2.Itinerary.defaults.ITEM_NAME_COVER": "Tour Cover",
    "v2.Itinerary.defaults.ITEM_NAME_TOUR_NAME": "Tour name",
    "v2.Itinerary.defaults.ITEM_NAME_CLIENT_INFO": "Subscriber details",
    "v2.Itinerary.defaults.ITEM_NAME_TRIP_HIGHLIGHTS": "You will love (trip highlights)",
    "v2.Itinerary.defaults.ITEM_NAME_TRIP_DESCRIPTION": "Trip Description",
    "v2.Itinerary.defaults.ITEM_NAME_TRIP_DATES": "Trip Dates",
    "v2.Itinerary.defaults.ITEM_NAME_TRIP_SOUNDCLOUD": "Audio track (SoundCloud)",
    "v2.Itinerary.defaults.ITEM_NAME_TRIP_VIDEO": "Youtube Video",
    "v2.Itinerary.defaults.ITEM_NAME_TRIP_FAQ": "Frequently Asked Questions (FAQ)",
    "v2.Itinerary.defaults.ITEM_NAME_COMPANY_INFO": "Organizer details",
    "v2.Itinerary.defaults.ITEM_NAME_DESTINATION_INFO": "Places of visit",
    "v2.Itinerary.defaults.ITEM_NAME_KNOW_BEFORE_GO": "Know before you go",
    "v2.Itinerary.defaults.ITEM_NAME_POI": "Description of the day",
    "v2.Itinerary.defaults.ITEM_NAME_ACTIVITY": "Activity / Service",
    "v2.Itinerary.defaults.ITEM_NAME_TRANSPORTATION": "Transportation",
    "v2.Itinerary.defaults.ITEM_NAME_FLIGHT": "Flight",
    "v2.Itinerary.defaults.ITEM_NAME_CAR_RENTAL": "Car Rental",
    "v2.Itinerary.defaults.ITEM_NAME_SLEEP": "Hotel",
    "v2.Itinerary.defaults.ITEM_NAME_PLACE": "Place",
    "v2.Itinerary.defaults.ITEM_NAME_PRICE": "Tour Price",
    "v2.Itinerary.defaults.ITEM_NAME_PRICE_included": "included",
    "v2.Itinerary.defaults.ITEM_NAME_PRICE_optional": "optional",
    "v2.Itinerary.defaults.ITEM_NAME_WHATS_INCLUDED": "What's included",
    "v2.Itinerary.defaults.ITEM_NAME_NEXT_STEPS": "Next steps - Booking this trip",
    "v2.Itinerary.defaults.ITEM_NAME_CANCELATION_POLICY": "Cancelation Policy",
    "v2.Itinerary.defaults.ITEM_NAME_CAROUSEL": "Photo gallery",
    "v2.Itinerary.defaults.ITEM_NAME_AUDIO": "Audio file",
    "v2.Itinerary.defaults.ITEM_NAME_VIDEO_FILE": "Video file",
    "v2.Itinerary.defaults.ITEM_NAME_CODE": "Source Code",
    "v2.Itinerary.defaults.DAY": "Day",
    "mainmenu.agency": "Creator",
    "mainmenu.configuration": "Configuration",
    "mainmenu.configuration_mobile": "Mobile App",
    "mainmenu.users": "Users",
    "mainmenu.billing": "Billing",
    "mainmenu.clients": "Subscribers",
    "mainmenu.itineraries": "Travel Assets",
    "mainmenu.templates": "Templates",
    "mainmenu.library": "Library",
    "onboarding.welcome": "Welcome To Morego!",
    "onboarding.subtitle": "We are very glad that you decided to join Morego and are here to give you a hand to get things going!",
    "onboarding.config_travel_agency": "Config Creator",
    "onboarding.config_profile": "Config your contacts and upload profile Picture",
    "onboarding.create_users": "Create more users",
    "onboarding.config_mobile_app": "Configure your Mobile App and request Build",
    "onboarding.create_a_client": "Create a new Subscriber",
    "onboarding.create_itinerary": "Create your first travel asset",
    "dashboard.stats.total_for_this_month": "Total for this month",
    "dashboard.stats.last_month": "Last Month",
    "dashboard.stats.itineraries_created": "Travel Assets Created",
    "dashboard.stats.itineraries_sold": "Number of ready travel assets",
    "dashboard.stats.new_clients": "New Subscribers",
    "dashboard.stats.library_growth": "Library growth",
    "dashboard.listings.last_modified_its": "Last Modified Travel Assets",
    "dashboard.listings.last_modified_its_desc": "The travel asset that were modified last byt the Creator",
    "dashboard.listings.about_to_start_its": "Travel Assets About to Start",
    "dashboard.listings.about_to_start_its_desc": "Travel Assets that start in the next month"
}
